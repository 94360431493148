import api from "../api";
//引入平台级元数据
import '../metadata';
//引入平台使用的vuex的store
import store from '../store';
!function (global) {
  //注册api
  api(dsf);
  dsf.mix(true, global.$platformStore, { ...store });
}(dsf.global || (dsf.global = {}))
